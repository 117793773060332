import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { useTranslation } from "next-i18next";
import CitiesAutocompleteList from "./citiesAutocompleteList";
import HotelsAutoCompleteList from "./hotelsAutocompleteList";

interface LocationList {
  cities: any[];
  hotels: any[];
  setLocationInputState: any;
  setShowLocations: Dispatch<SetStateAction<boolean>>;
  setLocationQuery: any;
  setNoAutoComplete: Dispatch<SetStateAction<boolean>>;
}
function LocationSelect({
  cities,
  hotels,
  setLocationInputState,
  setShowLocations,
  setLocationQuery,
  setNoAutoComplete,
}: LocationList) {
  const { t: translate } = useTranslation();
  return (
    <div className="z-40 flex w-full p-1 mt-5 overflow-y-scroll bg-white border-solid shadow-xl shadow-neutral lg:absolute lg:w-96 rounded-xl border-slate-400 max-h-72">
      <div className="flex flex-col w-full">
        <div className="grid flex-grow bg-white">
          {cities.length > 0 && (
            <CitiesAutocompleteList
              cities={cities}
              setLocationInputState={setLocationInputState}
              setLocationQuery={setLocationQuery}
              setShowLocations={setShowLocations}
              setNoAutoComplete={setNoAutoComplete}
            />
          )}
        </div>
        <div className="grid flex-grow bg-white card rounded-box ">
          {hotels.length > 0 && (
            <HotelsAutoCompleteList
              setLocationInputState={setLocationInputState}
              setLocationQuery={setLocationQuery}
              setShowLocations={setShowLocations}
              setNoAutoComplete={setNoAutoComplete}
              hotels={hotels}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default LocationSelect;
