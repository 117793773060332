import Head from "next/head";

interface SeoMetaTagsProps {
  title: string;
  description: string;
  keywords: string;
}

function SeoMetaTags({ title, description, keywords }: SeoMetaTagsProps) {
  const pageTitle = `MacQueen | ${title}`;
  return (
    <>
      <Head>
        <meta name="description" content={description} />
        <meta property="title" content={title} />
        <meta
          name="keywords"
          content={`${keywords}, travel, booking, Macqueen, flights, hotels, vacation, tourism, destinations, adventure, explore`}
        />
        <title>{pageTitle}</title>
      </Head>
    </>
  );
}

export default SeoMetaTags;
{
}
