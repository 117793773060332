import axios from "axios";
import { apiServices } from "utils/configs/apiServices";

export const getCitiesHotels = async (
  keyword: string,
  locale: any,
  country_code: string
) => {
  try {
    const countryCode = country_code ? country_code : "sa";
    const url = new URL(apiServices.search.autocomplete);
    const params = {
      query: keyword,
      lang: locale,
      country_code: countryCode.toLowerCase(),
    };
    url.search = new URLSearchParams(params).toString();

    const response = await axios.get(url.toString());

    return { data: response.data ? response.data : [] };
    // return { data: [] };
  } catch (error: any) {
    return { error: error.response };
  }
};
