interface Props {
  children: React.ReactNode;
  direction?: "row" | "column";
  align?: "start" | "end" | "center" | "stretch";
  justify?: "start" | "end" | "center" | "between" | "around";
  gap?: number;
  padding?: number;
  noWrap?: boolean;
  className?: string;
}
function FlexBox({
  direction = "row",
  justify = "start",
  align = "start",
  gap = 1,
  padding = 1,
  className = "",
  children,
}: Props) {
  return (
    <div
      className={`flex ${direction === "column" && "flex-col"}
       justify-${justify} items-${align} gap-${gap} p-${padding}
       ${className}
       `}
    >
      {children}
    </div>
  );
}

export default FlexBox;
