import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";

function CountdownTimer({ targetTime }: any) {
  const { t: translate } = useTranslation();
  const [countdownObject, setcountdownObject] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calcCountdown = ({ targetTime }: any) => {
    const countDownDate = new Date(targetTime).getTime();

    // Update the count down every 1 second
    const countInterval = setInterval(function () {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setcountdownObject({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });
    }, 1000);

    return countInterval;
  };
  useEffect(() => {
    const interval = calcCountdown({ targetTime });
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <h3 className="gap-2 mt-2 text-xl counter text-secondary opacity-70">
        <span>
          {countdownObject.days > 0 && countdownObject.days}{" "}
          {translate("general.Days")} : {"  "}
        </span>
        <span>
          {countdownObject.hours > 0 && countdownObject.hours}{" "}
          {translate("general.Hours")} :{"  "}{" "}
        </span>
        <span>
          {countdownObject.minutes > 0 && countdownObject.minutes}{" "}
          {translate("general.Minutes")} : {"  "}{" "}
        </span>
        <span>
          {countdownObject.seconds > 0 && countdownObject.seconds}{" "}
          {translate("general.Seconds")} {"  "}{" "}
        </span>
      </h3>
    </>
  );
}

export default CountdownTimer;
