import Modal from "react-modal";

interface Props {
  showModal: boolean;
  children: React.ReactNode;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isCLosalbe?: boolean;
  style?: {};
 }

function ModalBox({
  children,
  showModal,
  setShowModal,
  isCLosalbe = false,
  style,
 }: Props) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      zIndex: 100,
      borderRadius: "15px",
      padding: "15px",
      ...style,
    },
  };
  return (
    <Modal
      style={customStyles}
      isOpen={showModal}
      shouldCloseOnOverlayClick={isCLosalbe}
     >
      {children}
    </Modal>
  );
}

export default ModalBox;
