import { apiServices } from "utils/configs/apiServices";
import { axiosInstance } from "utils/configs/axiosInstance";
import { logError } from "utils/helpers/loggers";

export const registerDeviceToken = async (
  device_token: string | null,
  ip_address: string | null,
) => {
  const requestBody: any = {
    device_token,
    device_type: "portal",
    app_version: "22",
  };
  ip_address && (requestBody.ip_address = ip_address);
  try {
    const response = await axiosInstance.post(
      apiServices.deviceTokenRegister,
      requestBody
    );
    return { data: response.data };
  } catch (error: any) {
    logError(error as string)
    return { error: error.response.data.message };
  }
};
