import { useEffect, useState } from "react";
import { useUserStore } from "store/useUserStore";
import { registerDeviceToken } from "utils/api/registerDeviceToken";

export const useCampaignData = ({ ip }: any) => {
  const { deviceToken, setNationalityCode }: any = useUserStore();
  const [campaignData, setCampaignData] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const checkCampaignTimer = (campaignTimestamp: any) => {
    const maxTimeout = 1000 * 60 * 30; // 30 minutes
    return Date.now() - campaignTimestamp > maxTimeout;
  };

  const getCampaign = async () => {
     const { data } = await registerDeviceToken(deviceToken, ip);
     if (data) {
      localStorage.setItem(
        "searchEngine",
        data.search_version === 0 ? "GO" : "NODE"
      );
      setNationalityCode(data.server_country_code.toLowerCase());
      if (data.campaignData) {
        setCampaignData(data.campaignData);
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    if (!deviceToken) return;
    let campaignTimeStamp = localStorage.getItem("campaignTimeStamp");

    if (!campaignTimeStamp) {
      campaignTimeStamp = Date.now().toString();
      localStorage.setItem("campaignTimeStamp", campaignTimeStamp);
      (async () => await getCampaign())();
    }
    if (checkCampaignTimer(campaignTimeStamp)) {
      (async () => {
        await getCampaign();
        localStorage.setItem("campaignTimeStamp", Date.now().toString());
      })();
    }
  }, [deviceToken]);

  return { campaignData, showModal };
};
