import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useUserStore } from "store/useUserStore";
import percentage from "../../../public/assets/icons/20percentage.svg";
import promoImage from "../../../public/assets/icons/promo.svg";
import Button from "../elements/button";
import FlexBox from "../elements/flexBox";
import ModalBox from "../modalBox";
import { isGccCountry } from "utils/helpers/_general";
const PromotionModal = () => {
  const router = useRouter();
  const { user, nationalityCode }: any = useUserStore();
  const [showModal, setShowModal] = useState(false);
  const { locale } = useRouter();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (nationalityCode) {
      setShowModal(!user && isGccCountry(nationalityCode));
    }
  }, [nationalityCode]);

  return (
    <ModalBox
      style={{
        background: "linear-gradient(204.58deg, #00639E 32.89%, #00BEDD 100%)",
        padding: 25,
        zIndex: 999999,
        minWidth: "350px",
      }}
      showModal={showModal}
    >
      <FlexBox justify="between" align="center" gap={1}>
        <p className="text-white">{translate("promoContent.header")}</p>
        <button
          type="button"
          onClick={() => setShowModal(false)}
          style={{
            textAlign: "left",
            color: "white",
          }}
        >
          <IoClose />
        </button>
      </FlexBox>
      <FlexBox direction="column" justify="center" align="center" gap={1}>
        <Image
          className="animate-pulse"
          alt="gift"
          src={promoImage}
          width={240}
        />
        <h1 className="text-white">{translate("promoContent.heading")}</h1>
        <Image alt="gift" src={percentage} width={240} />
        <h2 className="text-white text-center lg:text-2xl md:text-1xl sm:text-1x1">
          {translate("promoContent.content")}
        </h2>
        <Button
          disabled={false}
          onClick={() => router.push("/register")}
          isLoading={false}
        >
          <p>{locale == "ar" ? "سجل واحصل على الخصم" : "Sign-up to receive"}</p>
        </Button>
      </FlexBox>
    </ModalBox>
  );
};

export default PromotionModal;
