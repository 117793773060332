import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { useUserStore } from "store/useUserStore";

import { useEffect, useState } from "react";
import { BiGlobe } from "react-icons/bi";
import { BsCheck, BsCheckLg, BsCurrencyExchange } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import UserHeaderNav from "../user/userHeaderNav";

import english from "public/assets/english.svg";
import saudi from "public/assets/saudi.svg";
import { AiOutlineMenu } from "react-icons/ai";
import { usePreference } from "store/usePreference";

import ResponsiveNavHeader from "components/responsive/reponsiveNavbar/navHeader";
import MobileNavigationMenu from "./mobileNavigationMenu";
import { logoUrl } from "constant/main";
export default function Header() {
  const { currency, setCurrentCurrency, setCurrentLang }: any = usePreference();

  const { t: translate } = useTranslation();
  const { locales, push, asPath, pathname, locale } = useRouter();
  const { token, user }: any = useUserStore();
  const [userLogged, setUserLogged] = useState(null);
  const [currentCurrency, setCurrency] = useState("");

  const [nav, setNav] = useState(false);
  const [lang, setLang] = useState(false);
  const [mobileUSDNav, setMobileUSDNav] = useState(false);

  const handleNav = () => {
    setNav(false);
    setLang(false);
    setMobileUSDNav(false);
  };
  useEffect(() => {
    setUserLogged(user);
    setCurrency(currency);
  }, []);

  return (
    <div className="flex justify-center w-full p-8 py-2 mx-auto md:justify-end">
      <AiOutlineMenu
        style={{ color: "grey" }}
        size={35}
        cursor={"pointer"}
        className={`absolute md:hidden top-5 
        ${locale === "en" ? "right-5" : "left-5"}
        `}
        onClick={() => setNav(!nav)}
      />
      <Link href="/">
       
      </Link>
      <div
        className={`${
          nav
            ? "flex md:flex-row flex-col md:relative absolute top-0 right-0 gap-3 z-50 bg-white w-full h-screen"
            : "flex flex-row"
        }`}
      >
        <ResponsiveNavHeader nav={nav} setNav={setNav} />
        <h5
          className={`mx-4 text-gray-200 text-sm uppercase md:hidden ${
            nav ? "" : "hidden"
          }`}
        >
          Other
        </h5>

        <div className={`flex gap-3  mr-2 ${nav ? "" : "hidden md:flex mr-0"}`}>
          <MobileNavigationMenu
            nav={nav}
            setNav={setNav}
            handleNav={handleNav}
            lang={lang}
            setLang={setLang}
            currentCurrency={currentCurrency}
            setCurrentCurrency={setCurrentCurrency}
            setCurrency={setCurrency}
            mobileUSDNav={mobileUSDNav}
            setMobileUSDNav={setMobileUSDNav}
            userLogged={userLogged}
          />
          <div className="hidden mt-2 dropdown dropdown-end md:flex">
            <div tabIndex={0} className="flex cursor-pointer ">
              <BiGlobe className="mt-[4px] mx-[5px] text-gray-400" size={20} />

              <h5 className="flex-grow-0 mt-1 text-sm uppercase text-slate-400 h-fit">
                {locale === "en" ? "EN" : "العربية"}
              </h5>
              <RiArrowDropDownLine className="text-slate-400" size={30} />
            </div>
            <ul
              tabIndex={0}
              className="p-2 mt-12 shadow text-primary dropdown-content menu bg-base-100 rounded-box w-52"
            >
              {locales?.map((lang) => (
                <li
                  className="text-sm uppercase hover:cursor-pointer "
                  onClick={() => {
                    setCurrentLang(lang);
                    push(pathname, asPath, { locale: lang });
                  }}
                  key={lang}
                >
                  <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                      <Image
                        src={lang === "en" ? english : saudi}
                        alt="sar"
                        width={30}
                        className="rounded-none"
                      />
                      <h3 className="normal-case">
                        {" "}
                        {lang === "en" ? "English" : "العربية"}
                      </h3>
                    </div>
                    {locale === lang ? <BsCheck size={25} /> : ""}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={`md:flex gap-3 hidden  ${nav ? "" : "hidden md:flex"}`}>
          <BsCurrencyExchange size={20} className="mt-3 text-slate-400" />
          <div className="mt-2 dropdown dropdown-end">
            <div tabIndex={0} className="flex cursor-pointer">
              <h5 className="flex-grow-0 mt-1 text-sm text-slate-400 h-fit">
                {currentCurrency}
              </h5>
              <RiArrowDropDownLine className="text-slate-400" size={30} />
            </div>
            <ul
              tabIndex={0}
              className="w-56 p-2 mt-5 text-sm shadow text-primary dropdown-content menu bg-base-100 rounded-box"
            >
              <li className="border-b border-solid ">
                <div
                  onClick={() => {
                    setCurrentCurrency("SAR");
                    setCurrency("SAR");
                  }}
                  className="flex gap-2 "
                >
                  <Image
                    src={saudi}
                    alt="sar"
                    width={30}
                    className="rounded-none"
                  />
                  <h3>Saudi Riyal - SAR</h3>
                  {currentCurrency === "SAR" && <BsCheckLg />}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    setCurrentCurrency("USD");
                    setCurrency("USD");
                  }}
                  className="flex gap-2 "
                >
                  <Image
                    src={english}
                    alt="usd"
                    width={30}
                    className="rounded-none"
                  />
                  <h3>US Dollar - USD</h3>
                  {currentCurrency === "USD" && <BsCheckLg />}
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* here the logic */}
        {userLogged && token && (
          <UserHeaderNav user={user} nav={nav} locale={locale} />
        )}
      </div>
    </div>
  );
}
