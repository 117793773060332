import { useSearchQueryStore } from "store/useSearchQueryStore";
import { BsClockHistory } from "react-icons/bs";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { useTranslation } from "next-i18next";
import { deleteRecentSearch } from "utils/api/search/getRecentSearch";
import { useUserStore } from "store/useUserStore";
import { useState } from "react";

function RecentSearchSelect({
  recentSearchList,
  setShowRecentSearch,
  setLocationQuery,
  setLocationInputState,
  setNoAutoComplete,
  setRecentSearchList,
}: any) {
  const { t: translate } = useTranslation();
  const { deviceToken }: any = useUserStore();

  const handleDeletRecentSearch = async () => {
    const response: any = await deleteRecentSearch(deviceToken);

    response.data && setRecentSearchList([]);
  };
  return (
    <div className="z-40 flex flex-col w-full p-5 pb-5 mt-6 overflow-y-scroll bg-white border-solid shadow-xl shadow-neutral lg:absolute lg:w-80 rounded-xl border-slate-400 max-h-64">
      <div className="flex justify-between">
        <h3 className="my-2 text-sm text-primary">
          {translate("general.Recent Searches")}
        </h3>
        <h5
          onClick={handleDeletRecentSearch}
          className="my-3 text-xs opacity-50 cursor-pointer hover:underline"
        >
          <MdOutlineDeleteSweep
            size={20}
            className="inline-block  -mt-0.5 opacity-50"
          />
          {translate("general.Clear history")}
        </h5>
      </div>
      <ul className="gap-3 text-xs">
        {recentSearchList.map((search: any) => {
          return (
            <li
              onClick={() => {
                setShowRecentSearch(false);
                setLocationQuery(
                  search.id,
                  search.type === "city"
                    ? `${search.name}, ${search.country_name}`
                    : search.name,
                  search.type
                );
                setLocationInputState(
                  search.type === "city"
                    ? `${search.name}, ${search.country_name}`
                    : search.name
                );
                setNoAutoComplete(true);
              }}
              className="p-3 rounded-lg hover:cursor-pointer hover:bg-gray-100"
              key={search.id}
            >
              <h3>
                <BsClockHistory className="inline-block mr-2 -mt-0.5 opacity-50" />{" "}
                {search.name}
                {search.type === "city" && (
                  <span className="opacity-50"> - {search.country_name}</span>
                )}
              </h3>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default RecentSearchSelect;
