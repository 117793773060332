import { useEffect, useRef } from "react";

import CountdownTimer from "./countdownTimer";
import dynamic from "next/dynamic";

import giftBox from "public/assets/animations/gift-offer.json";
const Lottie = dynamic(() => import("lottie-react"), {
  ssr: false,
});

function AlertModal({
  title,
  body = null,
  showModal,
  image,
  countdownDate,
}: any) {
  const modalRef = useRef<HTMLLabelElement>(null);
  useEffect(() => {
    if (showModal) {
      modalRef.current!.click();
    }
  }, [showModal]);
  return (
    <>
      <label ref={modalRef} htmlFor="my-modal-4" className="hidden btn">
        open modal
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my-modal-4" className="modal-toggle" />
      <label htmlFor="my-modal-4" className="cursor-pointer modal">
        <label
          className="relative flex flex-col items-center text-center modal-box"
          htmlFor=""
        >
          {image && (
            <Lottie
              animationData={giftBox}
              loop={false}
              width={6}
              style={{
                width: "55%",
                height: "55%",
              }}
            />
          )}
          {title && <h3 className="text-xl">{title}</h3>}
          {body && <p className="py-4">{body}</p>}
          <CountdownTimer targetTime={countdownDate} />
        </label>
      </label>
    </>
  );
}

export default AlertModal;
