import { useTranslation } from "next-i18next";
import Link from "next/link";
import paths from "utils/configs/paths";

export default function Footer() {
  const { t: translate } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <div className="flex flex-col-reverse items-center justify-between h-10 mt-5 text-xs md:flex-row">
      <h3 className="">
        &#169; {year} {translate("footer.MacQueenCopyright")}
      </h3>

      <div className="flex gap-2 text-xs text-slate-500">
        {/* <Link href={paths.static.}>{translate("footer.About us")}</Link> */}
        <Link href={paths.static.privacyPolicy}>
          {translate("footer.Privacy policy")}
        </Link>
        <Link href={paths.static.termsAndConditions}>
          {translate("footer.Terms and conditions")}
        </Link>
        <Link href={paths.static.aboutUs}>{translate("footer.About us")}</Link>
        {/* <Link>{translate("footer.MQN token")}</Link>
        <Link>{translate("footer.Invite program")}</Link>
        <Link>{translate("footer.Price guide")}</Link> */}
      </div>
    </div>
  );
}
