import Head from "next/head";
import { Fragment } from "react";
import Header from "./header";
import Footer from "./footer";

export default function Layout({ children }: any) {
  return (
    <Fragment>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-col w-11/12 min-h-screen py-2 mx-auto lg:w-9/12 md:page-background ">
        <Header />
        {children}
        <Footer />
      </div>
    </Fragment>
  );
}
