import { apiServices } from "utils/configs/apiServices";
import { axiosInstance } from "utils/configs/axiosInstance";
import { logError } from "utils/helpers/loggers";

export const getRecentSearch = async ({ deviceToken }: any) => {
  try {
    const response = await axiosInstance.get(
      apiServices.search.getRecentSearches + `?device_token=${deviceToken}`
    );
    return response.data;
  } catch (error: any) {
    logError(error);
    return error.response?.data;
  }
};

export const deleteRecentSearch = async (deviceToken: any) => {
  try {
    const response = await axiosInstance.delete(
      apiServices.search.getRecentSearches + `?device_token=${deviceToken}`
    );

    return { data: response.data };
  } catch (error: any) {
    return { error: error.response.data };
  }
};
