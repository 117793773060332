import Loader from "components/ui/elements/loader";
import moment from "moment";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { toast } from "react-toastify";
import { useSearchQueryStore } from "store/useSearchQueryStore";
import { validateBookingDates } from "utils/helpers/_general";
import SelectDatesCard from "./dates/selectDatesCard";
import LocationCard from "./location/locationCard";
import RoomsCard from "./rooms/roomsCard";
import { safelyArabicToEnglish } from "utils/helpers/date-helper";

interface HomeSearch {
  getCitiesHotels: (keyword: string) => void;
  cities: any[];
  hotels: any[];
}
interface SearchQueryStore {
  locationQueryId: string;
  checkInDate: string;
  checkOutDate: string;
  rooms: any[];
}

function HomeSearchBar() {
  const { push } = useRouter();
  const { t: translate } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    checkInDate,
    checkOutDate,
    totalAdults,
    totalChildren,
    rooms,
    locationQuery,
    setDatesQuery,
  }: any = useSearchQueryStore();

  const validateChildrenAges = () => {
    let isValid = true;
    rooms.forEach((room: any) => {
      if (room.children_ages.length > 0) {
        room.children_ages.forEach((childAge: any) => {
          if (childAge === "0") {
            isValid = false;
            toast.error(translate("searchBar.Please select children ages"));
          }
        });
      }
    });
    return isValid;
  };

  // validate chick in and check out dates to be at least 1 day and at most 30 days
  const validateDate = (checkInDate: any, checkOutDate: any) => {
    let isValid = true;
    const countOfDaysToCheck = moment(safelyArabicToEnglish(checkOutDate)).diff(
      moment(safelyArabicToEnglish(checkInDate)),
      "days"
    );

    const validtion = validateBookingDates(checkInDate);
    if (!validtion.valid) {
      isValid = false;
      toast.error(translate(`searchBar.${validtion.message}`));
      return isValid;
    }

    if (countOfDaysToCheck === 0) {
      isValid = false;
      toast.error(translate("searchBar.date range to be at least one day"));
      return isValid;
    }

    if (countOfDaysToCheck > 30) {
      isValid = false;
      toast.error(translate("searchBar.date range to be at most 30 days"));
      return isValid;
    }

    return isValid;
  };


  const submitSearch = () => {
    const isReservationPeriodValid = validateDate(checkInDate, checkOutDate);
    const isChildrenAgesValid = validateChildrenAges();

    if (!isChildrenAgesValid || !isReservationPeriodValid) return;

    setIsLoading(true);
    moment.locale("en");
    locationQuery.id &&
      push({
        pathname: "/hotels",
        query: {
          locationQuery: JSON.stringify(locationQuery),
          checkInDate: moment(safelyArabicToEnglish(checkInDate)).format("YYYY-MM-DD"),
          checkOutDate: moment(safelyArabicToEnglish(checkOutDate)).format("YYYY-MM-DD"),
          rooms: JSON.stringify(rooms),
          totalAdults,
          totalChildren,
        },
      });
    !locationQuery.id && setIsLoading(false);
  };

  return (
    <div className="flex flex-wrap gap-5 p-3 bg-white shadow-xl lg:flex-nowrap shadow-slate-100 lg:flex-row md:flex-col sm:flex-col sm:w-full xs:w-full md:w-full lg:justify-center lg:max-h-28 lg:rounded-full md:rounded-lg">
      {/* Search cards */}
      <div className="w-full lg:w-1/3">
        <LocationCard />
      </div>
      <div className="w-full lg:w-1/3">
        <SelectDatesCard />
      </div>
      <div className="w-full lg:w-1/3">
        <RoomsCard />
      </div>

      {/* Search button */}
      {isLoading ? (
        <div className="flex justify-center w-full xl:w-fit m:w-fit lg:w-fit">
          <Loader className="mt-8 mr-3" size={15} />
        </div>
      ) : (
        <button
          className="w-full mt-3 mr-3 rounded-full lg:w-14 h-14 btn btn-primary "
          onClick={submitSearch}
          style={{ fontWeight: 600 }}
        >
          <h3 className="mr-3 font-normal text-white normal-case lg:hidden">
            {translate("general.Search Hotels")}
          </h3>
          <FiSearch size={24} className="text-white fa-lg" />
        </button>
      )}
    </div>
  );
}

export default HomeSearchBar;
