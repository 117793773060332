import Layout from "components/layout/defaultLayout";
import Head from "next/head";
import Image from "next/image";
import giftBox from "../public/assets/icons/gift-box.svg";
import dynamic from "next/dynamic";

import AlertModal from "components/ui/alertModal";
import type { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import HomeSearchBar from "../components/searchBar/homeSearchBar";

import SeoMetaTags from "components/seo/seoMetaTags";
import { useCampaignData } from "hooks/useCampaignData";

import { useRouter } from "next/router";
import homeTravel from "public/assets/animations/home-travel.json";
import { getSeoPages } from "utils/api/seo/getSeoPages";
import { logoUrl } from "constant/main";
import { useEffect } from "react";
import { clearLocalStorageOnce } from "utils/helpers/_general";
import PromotionModal from "components/ui/modals/promotionModal";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { usePreference } from "store/usePreference";

const Lottie = dynamic(() => import("lottie-react"), {
  ssr: false,
});
export default function Home({ ip, seoHomePageData }: any) {
  const { locale } = useRouter();
  const { showModal, campaignData }: any = useCampaignData({ ip });

  useEffect(() => {
    clearLocalStorageOnce();
  }, []);

  return (
    <Layout>
      <Head>
        <title>MacQueen | Home</title>
      </Head>
      <SeoMetaTags
        title={
          locale == "en" ? seoHomePageData?.title : seoHomePageData?.title_ar
        }
        keywords={seoHomePageData?.tags + " " + seoHomePageData?.tags_ar}
        description={seoHomePageData?.description}
      />
      {campaignData && (
        <AlertModal
          title={
            locale === "en"
              ? campaignData!.campaign_title
              : campaignData!.campaign_title_ar
          }
          showModal={showModal}
          image={giftBox}
          countdownDate={campaignData!.expiration_date}
        />
      )}
      <main className="flex flex-col items-center justify-center flex-grow md:justify-start md:mt-14">
        <Image
          width={250}
          alt="Logo"
          height={100}
          src={locale === "en" ? logoUrl.en : logoUrl.ar}
          className="hidden mb-3 md:block p-3"
        />
        <Lottie
          animationData={homeTravel}
          loop={true}
          width={2}
          className="mb-3 md:hidden "
        />
        <HomeSearchBar />
        <PromotionModal />
      </main>
    </Layout>
  );
}
export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  locale,
}: any) => {
  let ip =
    req.headers["cf-connecting-ip"] ||
    req.headers["x-forwarded-for"]?.split(",")[0] ||
    req.headers["x-real-ip"] ||
    req.socket.remoteAddress;

  const seoPages = await getSeoPages();
  const seoHomePageData =
    seoPages.data?.find((item: any) => item.page === "HomePage") ?? null;
  return {
    props: {
      ip,
      ...(await serverSideTranslations(locale ?? "en")),
      seoHomePageData,
    },
  };
};
