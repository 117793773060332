interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  color?: "primary" | "secondary" | "success" | "error";
}

function Button({
  onClick: onClickHandler = () => {},
  disabled = false,
  isLoading = false,
  children,
  color = "primary",
}: Props) {
  return (
    <button
      onClick={onClickHandler}
      disabled={disabled}
      className={`text-white normal-case mt-3 rounded-xl btn btn-${color} ${
        isLoading ? "loading" : ""
      }`}
    >
      {children}
    </button>
  );
}

export default Button;
